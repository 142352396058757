/**
 * @description The footer for the site which contains static links contact information
 * and other pertinent data.
 *
 */

const Footer = () => {
  return (
    <>
      <div className='footer flex-row '>
        <div className='auto-center'>
          <p className='tag'>powered by Ogres</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
